<template>
    <div ref="screen" class="fixed inset-0 z-50 bg-white">
        <div ref="navbar" class="px-5 h-14 border-b flex justify-between items-center">
            <div class="flex items-center">
                <img src="@/assets/logo_dutapersada.png" class="block h-4" alt=""> <span class="font-semibold text-red-500 ml-1 text-base">Chat</span>
            </div>
            <div>NENDI</div>
        </div>
        <div ref="body" class="grid grid-cols-11">
            <!-- LIST USER -->
            <div class="col-span-2 border-r h-full">
                <div class="m-5">
                    <div>
                        <input type="text" class="h-9 rounded-sm border focus:outline-none px-3 w-full">
                    </div>
                </div>
                <ul ref="userlist" class="overflow-y-scroll">
                    <li v-for="i in 100" :key="i" class="flex items-center px-5 py-3 hover:bg-gray-100 cursor-pointer">
                        <div class="h-11 min-w-max  w-14 border">

                        </div>
                        <div class="ml-3 w-full">
                            <div class="font-semibold">Nendi Sofiandy</div>
                            <div class="text-gray-400 flex justify-between text-xs">
                                <div>
                                    <p class="truncate w-28">Tolong dibuatkan invoice untuk orderan a.n asep</p>
                                </div>
                                <div class="text-right">10 Apr</div>
                            </div>
                        </div>
                    </li>
                </ul>
            </div>

            <div class="col-span-9">
                <div class="border-b h-16 flex items-center px-5">
                    <div class="font-semibold text-lg">Nendi Sofiandy</div>
                </div>
                <div class="grid grid-cols-11 pb-16 h-full">
                    <div class="col-span-7 border-r relative">
                        <!-- CHAT LIST -->
                        <ul ref="contentChat" class="space-y-5 px-5 py-3 overflow-y-scroll">
                            <li v-for="i in 4" :key="i" @mousemove="buttonReply = i" @mouseleave="buttonReply=false">
                                <!-- PRODUCT LEFT CHAT -->
                                <div class="mb-5">
                                    <div class="flex items-center">
                                        <div class="bg-gray-100 max-w-md p-2 rounded-sm">
                                            <div class="mb-1">PRODUCT</div>
                                            <div class="flex items-center">
                                                <img src="https://cf.shopee.co.id/file/8d83d47b7aa0b03697aebeaa318b4d9c_tn" width="45" alt="">
                                                <div class="ml-3">
                                                    <div>Nama Produk atau judul produk yang panjang dan lengkap</div>
                                                    <div class="text-red-500">12,840,000.00</div>
                                                </div>
                                            </div>
                                        </div>
                                        <a @click="onReply(i)" href="#" v-show="buttonReply == i" class="bg-gray-100 ml-2 px-1 py-px border rounded-sm felx items-center text-gray-400 hover:text-red-500"><i class="icon-redo2"></i></a>
                                    </div>
                                    <div class="text-xs text-gray-400">13 Ags 2020, 14:32</div>
                                </div>
                                <!-- PRODUCT RIGHT CHAT -->
                                <div class="mb-5">
                                    <div class="flex items-center justify-end">
                                        <a @click="onReply(i)" href="#" v-show="buttonReply == i" class="bg-gray-100 mr-2 px-1 py-px border rounded-sm felx items-center text-gray-400 hover:text-red-500"><i class="icon-undo2"></i></a>
                                        <div class="bg-gray-100 max-w-md p-2 rounded-sm">
                                            <div class="mb-1">PRODUCT</div>
                                            <div class="flex items-center">
                                                <img src="https://cf.shopee.co.id/file/8d83d47b7aa0b03697aebeaa318b4d9c_tn" width="45" alt="">
                                                <div class="ml-3">
                                                    <div>Nama Produk atau judul produk yang panjang dan lengkap</div>
                                                    <div class="text-red-500">12,840,000.00</div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="text-xs text-gray-400 text-right">13 Ags 2020, 14:32</div>
                                </div>
                                <!-- LEFT CHAT -->
                                <div class="mb-5">
                                    <div class="flex items-center">
                                        <div class="inline-block max-w-md">
                                            <div class="bg-gray-100 p-2 rounded-sm">Lorem ipsum dolor, sit amet consectetur adipisicing elit. Velit neque quae nostrum nisi, dolores nesciunt nemo hic. Nostrum harum consectetur magni ipsa modi deserunt laborum explicabo, obcaecati at porro. Voluptates.</div>
                                        </div>
                                        <a @click="onReply(i)" href="#" v-show="buttonReply == i" class="bg-gray-100 ml-2 px-1 py-px border rounded-sm felx items-center text-gray-400 hover:text-red-500"><i class="icon-redo2"></i></a>
                                    </div>
                                    <div class="text-xs text-gray-400">13 Ags 2020, 14:32</div>
                                </div>

                                <!-- RIGHT CHAT -->
                                <div class="mb-5">
                                    <div class="flex items-center justify-end">
                                        <a @click="onReply(i)" href="#" v-show="buttonReply == i" class="bg-gray-100 mr-2 px-1 py-px border rounded-sm felx items-center text-gray-400 hover:text-red-500"><i class="icon-undo2"></i></a>
                                        <div class="inline-block max-w-md">
                                            <div class="bg-blue-100 p-2 rounded-sm">Lorem ipsum dolor, sit amet consectetur adipisicing elit.</div>
                                        </div>
                                    </div>
                                    <div class="text-xs text-gray-400 text-right">13 Ags 2020, 14:32</div>
                                </div>
                            </li>
                        </ul>
                        
                        <!-- TULIS PESAN -->
                        <div ref="tulisPesan" class="absolute bottom-0  border-t w-full py-3 bg-white">
                            <!-- Reply text -->
                            <div v-if="reply" class="flex items-center mx-2 mb-5 ">
                                <div class="bg-gray-200 border-l-4 border-green-500 p-2 w-full truncate">
                                    <div class="font-semibold">Nendi Sofiandy</div>
                                    <div class="truncate">Lorem ipsum dolor sit amet consectetur adipisicing elit. Dignissimos fugit cum non, obcaecati dicta natus iure explicabo enim ut, harum fugiat sequi at ducimus! Itaque similique maxime eius nemo maiores?</div>
                                </div>
                                <div class="px-3">
                                    <a @click="onReply('')" href="#"><i class="icon-cross2"></i></a>
                                </div>
                            </div>
                            
                            <div class="px-5">
                                <div class="mb-5"><a href="#" class="text-blue-300"><i class="icon-image2" style="font-size: 20px"></i></a></div>
                                <form action="">
                                    <div>
                                        <textarea class="w-full focus:outline-none resize-none overflow-y-auto break-words box-border" placeholder="Tulis pesan" rows="3"></textarea>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>

                    <!-- RIGHT CONTENT -->
                    <div class="col-span-4">
                        <div class="flex justify-around whitespace-nowrap cursor-pointer border-b relative">
                            <div @click="onTab('PRODUCT')" :class="{'border-red-500 text-red-500 hover:border-red-500': tab =='PRODUCT'}" class="border-b-2 flex-grow flex-shrink inline-block text-center pt-3 pb-2 border-transparent hover:border-gray-200 hover:text-red-600">PRODUCTS</div>
                            <div @click="onTab('QUOTATION')" :class="{'border-red-500 text-red-500 hover:border-red-500': tab =='QUOTATION'}" class="border-b-2 flex-grow flex-shrink inline-block text-center pt-3 pb-2 border-transparent hover:border-gray-200 hover:text-red-600">QUOTATIONS</div>
                            <div @click="onTab('SALES')" :class="{'border-red-500 text-red-500 hover:border-red-500': tab =='SALES'}" class="border-b-2 flex-grow flex-shrink inline-block text-center pt-3 pb-2 border-transparent hover:border-gray-200 hover:text-red-600">SALES ORDERS</div>
                        </div>
                        <!-- TAB PRODUCT -->
                        <div class="bg-gray-100 h-full">
                            <div class="w-full p-5">
                                <div class="form-feedback form-feedback-left">
                                    <input  type="text" class="form-control" placeholder="search product name or sku">
                                    <div class="form-control-feedback">
                                        <span>
                                            <i class="icon-search4 text-gray-400"></i>
                                        </span>
                                    </div>
                                    <div v-if="search"  class="absolute inset-y-0 flex items-center right-3">
                                        <a @click="search = ''" href="#">
                                            <i class="icon-cancel-circle2 text-red-500"></i>
                                        </a>
                                    </div>
                                </div>
                            </div>
                            <ul>
                                <li class="bg-white p-5">
                                    <div class="flex items-center">
                                        <img src="http://localhost:3000/public/img/products/200/1656404182481.jpeg" class="border rounded-sm overflow-hidden" width="49" alt="">
                                        <div class="ml-2">
                                            <h5 class="leading-none">Flowatch FL-01 Display Unit Only Flowmeter Current for FL-03 FL-02</h5>
                                            <div class="text-red-500">120,000,000</div>
                                        </div>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data () {
        return {
            buttonReply: false,
            reply: '',
            tab: 'PRODUCT'
        }
    },
    mounted () {
        let hbody = this.$refs.screen.clientHeight - 55;
        let userListHeight = hbody - 77
        this.$refs.body.style.height = hbody+'px'
        this.$refs.userlist.style.height = userListHeight+'px'
        let tulisPesan = this.$refs.tulisPesan.clientHeight
        this.$refs.contentChat.style.height = hbody - tulisPesan - 63+'px'
        window.addEventListener('resize', () => {
            this.$refs.screen.style.height = window.innerHeight+'px'
            let hbody = this.$refs.screen.clientHeight - 55;
            let userListHeight = hbody - 77
            this.$refs.body.style.height = hbody+'px'
            this.$refs.userlist.style.height = userListHeight+'px'
            this.$refs.contentChat.style.height = hbody - tulisPesan - 63+'px'

        })
    },
    methods: {
        onReply (i) {
            this.reply = i
            this.$nextTick(() => {
                let hbody = this.$refs.screen.clientHeight - 55;
                let tulisPesan = this.$refs.tulisPesan.clientHeight
                this.$refs.contentChat.style.height = hbody - tulisPesan - 63+'px'
                console.log(tulisPesan);
            })
        },
        onTab (i) {
            this.tab = i
        }
    }
}
</script>